<script>
import { vMaska } from "maska";
import { defineComponent } from "vue";
export default defineComponent({
  name: "AppInput",

  emits: ["update:modelValue"],

  props: {
    modelValue: {
      type: [String, Number],
      default: "",
    },

    id: {
      type: String,
      default: "",
    },

    label: {
      type: String,
      default: "",
    },

    placeholder: {
      type: String,
      default: "",
    },

    type: {
      type: String,
      default: "text",
    },

    error: {
      type: String,
      default: "",
    },

    hideError: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
    },

    required: {
      type: Boolean,
    },

    notRequired: {
      type: Boolean,
    },

    size: {
      type: String,
      default: "large",
    },

    mask: {
      type: String,
    },

    inputMode: {
      type: String,
      default: "text",
    },

    maxlength: {
      type: Number,
    },
  },

  directives: { maska: vMaska },

  data() {
    return {
      show_clear_button: false,
    };
  },

  computed: {
    formattedValue: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit("update:modelValue", newValue);
      },
    },
  },

  methods: {
    updateInputValue(event) {
      this.$emit("update:modelValue", event.target.value);
    },

    clearInput() {
      this.$emit("update:modelValue", "");
    },
  },
});
</script>

<template>
  <div class="app-input">
    <div class="app-input__header" v-if="label">
      <label v-if="label" :for="id">
        {{ label }}
        <span class="app-input_required" v-if="required">*</span>
        <span class="app-input_not-required" v-if="notRequired">
          - Необязательно
        </span>
      </label>

      <span
        class="app-input__max-length"
        :class="{
          'app-input__max-length_error': formattedValue.length === maxlength,
        }"
        v-if="maxlength"
      >
        {{ formattedValue.length }}/{{ maxlength }}
      </span>
    </div>

    <div
      class="input"
      :class="{
        input_error: error,
        input_disabled: disabled,
        input_medium: size === 'medium',
        input_small: size === 'small',
      }"
    >
      <slot name="left" />

      <input
        :type="type"
        :id="id"
        v-model="formattedValue"
        @input="updateInputValue"
        :disabled="disabled"
        :placeholder="placeholder"
        v-maska
        :data-maska="mask"
        :inputmode="inputMode"
        :maxlength="maxlength"
      />

      <button
        @click="clearInput"
        v-if="formattedValue && formattedValue.length && !disabled"
        type="button"
      >
        <i class="icon icon-close" />
      </button>

      <slot name="right" />
    </div>

    <span class="error" v-if="error && !hideError">
      {{ error }}
    </span>
  </div>
</template>

<style scoped lang="scss">
.app-input {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: column;
  gap: $base-space * 2;
  width: 100%;
  transition: ease-out 0.05s;

  &__header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > label {
      color: $text-dark-second;
      @include s-text-regular();
      padding: 0 0 0 ($base-space);
    }
  }

  &_required {
    @include s-text-regular();
    color: $text-error;
  }

  &_not-required {
    @include s-text-regular();
    color: $text-fifth;
  }

  > .input {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: $base-space;
    border: 1px solid $stroke-second;
    border-radius: 8px;
    height: 56px;
    padding: 0 ($base-space * 4);

    @media (max-width: 1023px) {
      height: 48px;
    }

    &:hover {
      border-color: $stroke-primary;

      > button {
        display: block;
      }
    }

    &:deep(i) {
      width: 24px;
      height: 24px;
      background: $text-dark-placeholder;
    }

    > input {
      width: 100%;
      height: 100%;
      @include m-text-regular();
      color: $text-dark-primary;
      padding: 0;
      background: none;
      border: none;
      outline: none;

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-background-clip: text;
        -webkit-text-fill-color: $text-dark-primary;
        box-shadow: none;
      }

      &::placeholder {
        color: $text-dark-placeholder;
      }
    }

    &:focus-within {
      border-color: $stroke-accent;

      > button {
        display: block;
      }
    }

    &_error {
      border-color: $stroke-error;
    }

    &_disabled {
      border-color: $stroke-fourth;

      &:hover {
        border-color: $stroke-fourth;
      }

      > input {
        color: $text-dark-third;
        cursor: not-allowed;
      }
    }

    &_medium {
      height: 48px;

      @media (max-width: 1023px) {
        height: 40px;
        padding: 0 ($base-space * 3);

        > input {
          width: calc(100% - 24px);
        }
      }

      > &:deep(i) {
        width: 20px;
        height: 20px;
      }
    }

    &_small {
      height: 44px;
      padding: 0 ($base-space * 3);

      > input {
        width: calc(100% - 24px);
      }

      > &:deep(i) {
        width: 20px;
        height: 20px;
      }
    }

    > button {
      display: none;
    }
  }

  &__max-length {
    @include s-text-regular();
    color: $text-dark-second;

    &_error {
      color: $text-error;
    }
  }
}
</style>

import API from "@/api";

const state = {
  course: "",
  courses_stats: "",
  lesson: "",
  lessons_labels: "",
};

const getters = {
  getCourseStats: (state) => (id) => {
    if (state.courses_stats.length) {
      return state.courses_stats.find((course) => course.id === id);
    }

    return "";
  },

  getCourseProgress: (state) => (id) => {
    if (state.courses_stats.length) {
      let course = state.courses_stats.find((course) => course.id === id);
      return parseInt((course.completed_units / course.total_units) * 100);
    }

    return 0;
  },

  getLessonNumber: (state) => (id) => {
    return state.lessons_labels.find((lesson) => lesson.id === id);
  },
};

const mutations = {
  SET_COURSE_INFORMATION(store, payload) {
    store.course = payload;
  },

  SET_COURSES_STATS(store, payload) {
    store.courses_stats = payload;
  },

  SET_LESSON_INFORMATION(store, payload) {
    store.lesson = payload;
  },

  SET_COURSE_LABELS(store, payload) {
    store.lessons_labels = payload;
  },
};

const actions = {
  loadCourseInformationById({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.get(`api/users/courses/${data}`)
        .then((response) => {
          commit("SET_COURSE_INFORMATION", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadCourseUnits(_, data) {
    return new Promise((resolve, reject) => {
      API.get(`api/users/courses/${data}/units`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadCourseUnit({ commit }, data) {
    commit("SET_LESSON_INFORMATION", "");

    return new Promise((resolve, reject) => {
      API.get(`api/users/courses/${data.course_id}/units/${data.lesson_id}`)
        .then((response) => {
          commit("SET_LESSON_INFORMATION", response.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  completeCourseUnit(_, data) {
    return new Promise((resolve, reject) => {
      API.post(`api/users/courses/${data.course_id}/units/${data.lesson_id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadCourseStatus({ commit }, data) {
    commit("SET_COURSES_STATS", "");

    return new Promise((resolve, reject) => {
      API.post(`api/users/courses/statuses`, data)
        .then((response) => {
          commit("SET_COURSES_STATS", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadCourseLabels({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.get(`api/users/courses/${data}/units/labels`)
        .then((response) => {
          commit("SET_COURSE_LABELS", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  markCompletedCourse(_, data) {
    return new Promise((resolve, reject) => {
      API.post(`api/users/courses/${data}/mark`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  loadLessonTask(_, data) {
    return new Promise((resolve, reject) => {
      API.get(
        `api/users/courses/${data.course_id}/units/${data.lesson_id}/tasks`
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  sendLessonTask(_, data) {
    return new Promise((resolve, reject) => {
      API.post(
        `api/users/courses/${data.course_id}/units/${data.lesson_id}/tasks`,
        data.data
      )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
